import { getUserData } from '@/auth/utils'
import jwtPythonApi from './jwtPythonApi'

class JwtServiceMore {
  constructor() {
    Object.assign(JwtServiceMore.prototype, jwtPythonApi)
  }

  getCalendarTemplate(...args) {
    return this.axiosIns.get(`${this.jwtConfig.calendarEventTemplateEndpoint}`, ...args)
  }

  storeCalendarTemplate(...args) {
    return this.axiosIns.post(`${this.jwtConfig.calendarEventTemplateEndpoint}`, ...args)
  }

  showCalendarTemplate(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.calendarEventTemplateEndpoint}/${id}`, ...args)
  }

  deleteCalendarTemplate(id) {
    return this.axiosIns.delete(`${this.jwtConfig.calendarEventTemplateEndpoint}/${id}`)
  }

  applyCalendarTemplate(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.calendarEventTemplateEndpoint}/${id}/apply`, ...args)
  }

  cloneCalendarTemplate(id) {
    return this.axiosIns.post(`${this.jwtConfig.calendarEventTemplateEndpoint}/${id}/clone`)
  }

  toggleCalendarTemplateVisibility(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.calendarEventTemplateEndpoint}/${id}/visibility`, ...args)
  }

  //   Verify Workzone
  verfiyWorkzoneLogin() {
    return this.axiosIns.get(`${this.jwtConfig.verfiyWorkzoneLogin}`)
  }

  getGameReport(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.gameEndPoint}/get-game-report/${id}`, ...args)
  }

  getEbookByVersions(lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonPagesEndpoint}/${lessonId}/get-ebook`, ...args)
  }

  getStudentRoleStat({ userId, classId }) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${userId}/scoreboards/${classId}`)
  }

  getStudentNoteStat({ userId, classId }) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${userId}/notes/${classId}`)
  }

  getGroupNoteReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/notes-report`)
  }

  getGroupChatReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/chat-report`)
  }

  getScheduleReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/schedule`)
  }

  getRoleReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/role-report`)
  }

  getStudentPaired(...args) {
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/get-all-students`, ...args)
  }

  getProblemScore(testId) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/get-problem-score/${testId}`)
  }

  updateProblemScore(testId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.apiEndPoint}/update-problem-score/${testId}`, ...args)
  }

  getSpeechAccuracyByTestStat(testId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.apiEndPoint}/get-speech-accuracy/${testId}`, ...args)
  }

  updateOpenAiScore(testId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.apiEndPoint}/update-open-ai-score/${testId}`, ...args)
  }

  getScormPlayer(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.scormEndPoint}/play/${uuid}`)
  }

  getScormList() {
    return this.axiosIns.get(`${this.jwtConfig.scormEndPoint}`)
  }

  getScormReport(scosUiId) {
    return this.axiosIns.get(`${this.jwtConfig.scormEndPoint}/track/${scosUiId}/get-report`)
  }

  getGecScromList() {
    return this.axiosIns.get(`${this.jwtConfig.scormEndPoint}/lists`)
  }

  deleteScorm(id) {
    return this.axiosIns.delete(`${this.jwtConfig.scormEndPoint}/${id}`)
  }

  showLibrary(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.libraryEndPoint}/${id}`, ...args)
  }

  translateLibrary(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}/translate/${id}`, ...args)
  }

  updateBlockedUserCourses(...args) {
    return this.axiosIns.post(`${this.jwtConfig.libraryEndPoint}/update-blocked-course`, ...args)
  }

  getBlockedUserCourses(userId) {
    return this.axiosIns.get(`${this.jwtConfig.libraryEndPoint}/blocked-course/${userId}`)
  }

  storeSchoolLibrary(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.libraryEndPoint}/${id}/update-school`, ...args)
  }

  storeLibrary(...args) {
    return this.axiosIns.post(`${this.jwtConfig.libraryEndPoint}`, ...args)
  }

  updateLibrary(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.libraryEndPoint}/${id}`, ...args)
  }

  getLibrary(...args) {
    return this.axiosIns.get(`${this.jwtConfig.libraryEndPoint}`, ...args)
  }

  requestLibrary(...args) {
    return this.axiosIns.post(`${this.jwtConfig.libraryEndPoint}/request`, ...args)
  }

  toggleStatementFeedBackAnimationTemplate(templateId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.animationTemplateEndPoint}/statement-feedback-toggle/${templateId}`, ...args)
  }

  // animation template
  saveAnimationTemplate(...args) {
    return this.axiosIns.post(`${this.jwtConfig.animationTemplateEndPoint}`, ...args)
  }

  // animation-template/bulk-update/{lesson}
  bulkUpdateAnimatedTemplate(lesson, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.animationTemplateEndPoint}/bulk-update/${lesson}`, ...args)
  }

  toggleAnimationTemplate(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.animationTemplateEndPoint}/toggle/${id}`, ...args)
  }

  createAnimationTemplate(...args) {
    return this.axiosIns.post(`${this.jwtConfig.animationTemplateEndPoint}`, ...args)
  }

  saveAnimationTemplateCopy(id, args) {
    return this.axiosIns.post(`${this.jwtConfig.animationTemplateEndPoint}/save-from/${id}`, args)
  }

  getSavedAnimationTemplateCopy() {
    return this.axiosIns.get(`${this.jwtConfig.animationTemplateEndPoint}/get-saved-template`)
  }

  updateAnimationTemplate(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.animationTemplateEndPoint}/${id}`, ...args)
  }

  destroyAnimationTemplate(id) {
    return this.axiosIns.delete(`${this.jwtConfig.animationTemplateEndPoint}/${id}`)
  }

  getAnimationTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.animationTemplateEndPoint}/${id}`)
  }

  toggleAllVisibility(action, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemVisibilityEndpoint}/${action}`, ...args)
  }

  getStudentProblemSets(...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemSetsEndPoint}/student-sets`, ...args)
  }

  updateStudentProblemSets(...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemSetsEndPoint}/update-sets`, ...args)
  }

  // for builder
  getProblemSetWrapperLists(lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.problemSetsEndPoint}/get-problem-set/${lessonId}`)
  }

  createProblemSetWrapper(lessonId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemSetsEndPoint}/create-set/${lessonId}`, ...args)
  }

  deleteProblemSetWrapper(setId) {
    return this.axiosIns.delete(`${this.jwtConfig.problemSetsEndPoint}/delete-set/${setId}`)
  }

  checkAndGetClassTests(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/check-and-get-class-tests`)
  }

  // reservations
  getReservations(...args) {
    return this.axiosIns.get(this.jwtConfig.reservationEndPoint, ...args)
  }

  storeReservations(form) {
    return this.axiosIns.post(this.jwtConfig.reservationEndPoint, form)
  }

  showReservations(id) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/${id}`)
  }

  updateReservations(id, form) {
    return this.axiosIns.put(`${this.jwtConfig.reservationEndPoint}/${id}`, form)
  }

  deleteReservations(id) {
    return this.axiosIns.delete(`${this.jwtConfig.reservationEndPoint}/${id}`)
  }

  getShopifyProducts(collection) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-shopify-product/${collection}`)
  }

  updateShopifyProductsForReservation(reservationId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/update-shopify-product/${reservationId}`, ...args)
  }

  getAvailableReservation(...args) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-reservations`, ...args)
  }

  storePublicReservation(form) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/public/store`, form)
  }

  getPublicReservation(...args) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/public/list`, ...args)
  }

  destroyPublicReservation(id) {
    return this.axiosIns.delete(`${this.jwtConfig.reservationEndPoint}/public/delete/${id}`)
  }

  getDefaultClassroom(...args) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-default-classroom`, ...args)
  }

  reservationToClassRoom(form) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/reservation-to-class`, form)
  }

  getReservationPublicClassRoom(id) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/${id}/get-reservation-public-class`)
  }

  getPublicSchools() {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/public/schools`)
  }

  getSchoolReservation(schoolId) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}?schoolId=${schoolId}`)
  }

  onConfirmTeacherReservation(form) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/reserve-schedule`, form)
  }

  getPageFromProblem(problemId) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/get-page-from-problem/${problemId}`)
  }

  getMismatchedCbProblems(...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/check-cb-lesson-problem`, ...args)
  }

  //   Market Places

  getMarketPlaces(...args) {
    return this.axiosIns.get(this.jwtConfig.marketPlaceEndPoint, ...args)
  }

  getSchoolMarketPlaces(id, form) {
    return this.axiosIns.get(`${this.jwtConfig.marketPlaceEndPoint}/school-marketplaces/${id}`, form)
  }

  storeMarketPlaces(form) {
    return this.axiosIns.post(this.jwtConfig.marketPlaceEndPoint, form)
  }

  showMarketPlace(id) {
    return this.axiosIns.get(`${this.jwtConfig.marketPlaceEndPoint}/${id}`)
  }

  updateMarketPlaces(id, form) {
    return this.axiosIns.put(`${this.jwtConfig.marketPlaceEndPoint}/${id}`, form)
  }

  changeMarketPlacesStatus(id, form) {
    return this.axiosIns.post(`${this.jwtConfig.marketPlaceEndPoint}/change-status/${id}`, form)
  }

  deleteMarketPlaces(id) {
    return this.axiosIns.delete(`${this.jwtConfig.marketPlaceEndPoint}/${id}`)
  }

  getSignalClassroomOverallReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/overall-report`)
  }

  getOverallClassroomReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/overall-classroom-report`, ...args)
  }

  //   Animated Items

  getAnimatedItems(...args) {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsEndpoint}`, ...args)
  }

  getAnimatedMarketplace() {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsEndpoint}-marketplace`)
  }

  storeAnimatedItems(form) {
    return this.axiosIns.post(`${this.jwtConfig.animatedItemsEndpoint}`, form)
  }

  updateAnimatedItems(id, form) {
    return this.axiosIns.put(`${this.jwtConfig.animatedItemsEndpoint}/${id}`, form)
  }

  deleteAnimatedItems(id) {
    return this.axiosIns.delete(`${this.jwtConfig.animatedItemsEndpoint}/${id}`)
  }

  buyAnimatedItems(id) {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsEndpoint}/buy/${id}`)
  }

  myAnimatedItems(character = 'all') {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsEndpoint}/user/my-items/${character}`)
  }

  getSelectedOutfits() {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsEndpoint}/user/selected-outfits`)
  }

  updateSelectedOutfits(form) {
    return this.axiosIns.post(`${this.jwtConfig.animatedItemsEndpoint}/user/selected-outfit/update`, form)
  }

  //   Trading of Animated Items
  getTradedItems() {
    return this.axiosIns.get(`${this.jwtConfig.animatedItemsTradingEndpoint}`)
  }

  sellAnimatedItems(form) {
    return this.axiosIns.post(`${this.jwtConfig.animatedItemsTradingEndpoint}/sell`, form)
  }

  buyTradingAnimatedItems(id, form) {
    return this.axiosIns.post(`${this.jwtConfig.animatedItemsTradingEndpoint}/buy/${id}`, form)
  }

  removeStatementFeedBackTemplate(templateId) {
    return this.axiosIns.delete(`${this.jwtConfig.problemTemplatesEndpoint}/${templateId}`)
  }

  getLanguageLevelByCourse(...args) {
    return this.axiosIns.get(`${this.jwtConfig.globalSettingEndpoint}/get-lang-level`, ...args)
  }

  getHigherLevelReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.higherLevelReportingEndPoint}`, ...args)
  }

  restoreUser(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.userEndpoint}/restore/${id}`, ...args)
  }

  updateClassroomScript(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/${id}/scripts`, ...args)
  }

  getClassroomScript(id) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/scripts`)
  }

  // report
  getCalibratedClassCourseReport(classId, courseId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.calibratedReportEndPoint}/${classId}/course/${courseId}`, ...args)
  }

  getCalibratedClassLessonReport(classId, lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.calibratedReportEndPoint}/${classId}/lesson/${lessonId}`, ...args)
  }

  getCalibratedCourseUserReport(classId, courseId, userId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.calibratedReportEndPoint}/${classId}/course/${courseId}/user/${userId}`, ...args)
  }

  getCourseListForReport(roomId) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/reports/classroom/${roomId}/courses`)
  }

  getClassroomPublicScript() {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/classroom-script/public-script`)
  }

  applyClassroomPublicScript(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/${id}/apply-public-script`, ...args)
  }

  getIndividualUserReport(userId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/user-all-report/${userId}`, ...args)
  }

  getIndividualUserStudyTimeReport({ userId, classId }) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/get-user-study-time/${classId}/${userId}`)
  }

  getIndividualUserCourseGraphReport(userId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/user-all-report/lesson-group-graph/${userId}`, ...args)
  }

  // roles
  storeRoles(...args) {
    return this.axiosIns.post(`${this.jwtConfig.rolesEndpoint}/store`, ...args)
  }

  updateRoles(role, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.rolesEndpoint}/update/${role}`, ...args)
  }

  deleteRoles(role, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.rolesEndpoint}/delete/${role}`, ...args)
  }

  getRoles(...args) {
    return this.axiosIns.get(`${this.jwtConfig.rolesEndpoint}/index`, ...args)
  }

  getModuleRoles(...args) {
    return this.axiosIns.get(`${this.jwtConfig.rolesEndpoint}/module-role`, ...args)
  }

  searchPermission(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissionsEndpoint}/permission-search`, ...args)
  }

  // student calibration
  saveStudentCalibration(...args) {
    return this.axiosIns.post(`${this.jwtConfig.calibratedReportEndPoint}/save-student-calibration`, ...args)
  }

  // student calibration
  getStudentCalibration(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.calibratedReportEndPoint}/${classId}/get-student-calibration`, ...args)
  }

  // student calibration cefr
  getStudentCalibrationCefr(classId, lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/cefr/classroom/${classId}/lesson/${lessonId}`, ...args)
  }

  // image library api
  getImageLibrary(...args) {
    return this.axiosIns.get(`${this.jwtConfig.imageLibraryEndpoint}`, ...args)
  }

  storeImageLibrary(...args) {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}`, ...args)
  }

  updateImageLibrary(imageId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.imageLibraryEndpoint}/${imageId}`, ...args)
  }

  updateImageLibraryWithGenerateImage(...args) {
    return this.axiosIns.put(`${this.jwtConfig.imageLibraryEndpoint}/update/generated-img`, ...args)
  }

  deleteImageLibrary(imageId) {
    return this.axiosIns.delete(`${this.jwtConfig.imageLibraryEndpoint}/${imageId}`)
  }

  getImageLibraryById(imageId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.imageLibraryEndpoint}/${imageId}`, ...args)
  }

  saveImageLibraryWord(...args) {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}/regenerate`, ...args)
  }

  saveImageLibraryWordForGeneratedWord(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.wordsForParagraphEndPoint}/${id}/generate-from-lib`, ...args)
  }

  getOnProgressFailedImages() {
    return this.axiosIns.get(`${this.jwtConfig.failedOnprogressImageLibrary}`)
  }

  imageLibraryBulkUpload(...args) {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryBulkUploadEndpoint}`, ...args)
  }

  updateStudentLessonCalibration(lessonId) {
    return this.axiosIns.post(`${this.jwtConfig.calibratedReportEndPoint}/update-student-calibration/${lessonId}`)
  }

  getEssayCounts(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/essay/count`)
  }

  getCefrStatusOfLesson(lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/cefr/classroom/${lessonId}/status`)
  }

  updateCefrStatusOfLesson(lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/cefr/classroom/${lessonId}/sync`, ...args)
  }

  //   Get classroom for user
  getClassRoomOfUser(user) {
    return this.axiosIns.get(`${this.jwtConfig.classRoomEndpoint}/${user}`)
  }

  getClassRoomSettings(id) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/settings`)
  }

  getSchoolSettings() {
    if (!getUserData()) {
      return new Promise(resolve => {
        resolve({})
      })
    }
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/get-school-enhancement/setting`)
  }

  updateSchoolSettings(type, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/update-school-enhancement/${type}`, ...args)
  }

  updateClassroomSelfStudy(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/${id}/selfStudyClassroom`, ...args)
  }

  updateClassroomSetting(id, setting, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/${id}/setting/${setting}`, ...args)
  }

  getClassroomSetting(id, setting) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/setting/${setting}`)
  }

  verifyShopifyReservation(...args) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/shopify/verify`, ...args)
  }

  updateUserPermission(...args) {
    return this.axiosIns.post(`${this.jwtConfig.userPermissionEndpoint}`, ...args)
  }

  storeProblemWithVideo(problemId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.questionEndpoint}/clone-with/${problemId}`, ...args)
  }

  getWriteShiftApiResponse(args) {
    return this.axiosIns.post(`${this.jwtConfig.apiEndPoint}/call-write-shift-api`, args)
  }

  //   Lesson Planner
  getLessonPlanner(...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonPlannerEndpoint}`, ...args)
  }

  createLessonPlanner(...args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonPlannerEndpoint}`, ...args)
  }

  updateLessonPlanner(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.lessonPlannerEndpoint}/${id}`, ...args)
  }

  fetchCurrentLessonPlanner(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonPlannerEndpoint}/${id}/edit`, ...args)
  }

  removeLessonPlanner(id, all = 0) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonPlannerEndpoint}/${id}?${all ? `all=${all}` : ''}`)
  }

  // ai-ide
  showAiIdePage(...args) {
    return this.axiosIns.get(`${this.jwtConfig.aiIdeEndPoint}/get-page`, ...args)
  }

  destroyAiIdePage(id) {
    return this.axiosIns.delete(`${this.jwtConfig.aiIdeEndPoint}/ide-pages/${id}`)
  }

  storeAiIdePage(...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/ide-pages`, ...args)
  }

  createAiIdeProcess(pageId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/create-process/${pageId}`, ...args)
  }

  createAiIdeTask(pageId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/create-task/${pageId}`, ...args)
  }

  destroyAiIdeTask(id) {
    return this.axiosIns.delete(`${this.jwtConfig.aiIdeEndPoint}/delete-task/${id}`)
  }

  updateAiIdeProcess(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.aiIdeEndPoint}/update-process/${id}`, ...args)
  }

  enableDisableIdeProcess(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.aiIdeEndPoint}/enable-process/${id}`, ...args)
  }

  deleteAiIdeProcess(id) {
    return this.axiosIns.delete(`${this.jwtConfig.aiIdeEndPoint}/delete-process/${id}`)
  }

  getStudentBotChatHistory(args) {
    return this.axiosIns.get(`${this.jwtConfig.aiIdeEndPoint}/get-student-chat-history`, args)
  }

  saveStudentBotChatHistory(args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/save-student-chat-history`, args)
  }

  getBotResponse(...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/get-bot-response`, ...args)
  }

  // lesson links
  getLessonLinks(...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonLinkEndpoints}`, ...args)
  }

  getUrlFromLessonLink(id) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/get-lesson-link/${id}`)
  }

  saveLessonLinks(...args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonLinkEndpoints}`, ...args)
  }

  editLessonLinks(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.lessonLinkEndpoints}/${id}`, ...args)
  }

  deleteLessonLinks(id) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonLinkEndpoints}/${id}`)
  }

  changeGlobalCEFR(...args) {
    return this.axiosIns.post(`${this.jwtConfig.cefrScopeEndpoint}`, ...args)
  }

  translateCurriculumLesson(cbPage, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.curriculumPagesEndpoint}/${cbPage}/translate`, ...args)
  }

  getCoursesLessonWithLibrary() {
    return this.axiosIns.get(`${this.jwtConfig.libraryEndPoint}/courses/lessons`)
  }

  getSsoLink(provider) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/sso/login/${provider}`)
  }

  loginWithSSoProvider(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}/with-provider`, ...args)
  }

  getDefaultChatOfUser(...args) {
    return this.axiosIns.get('/api/v1/default-chat', ...args)
  }

  getReservationSchedules(classId) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-class-schedule/${classId}`)
  }

  updateReservationSchedules(classId, scheduleId) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/select-class-schedule/${classId}/${scheduleId}`)
  }

  showScheduleDetail(scheduleId) {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-schedule-detail/${scheduleId}`)
  }

  startScheduleClass(scheduleId) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/start-class/${scheduleId}`)
  }

  getStudentScheduleGroup() {
    return this.axiosIns.get(`${this.jwtConfig.reservationEndPoint}/get-student-schedules`)
  }

  getOpenAIScoreForDemo(...args) {
    return this.axiosIns.post(`${this.jwtConfig.getDemoEndpoint}/ai-score`, ...args)
  }

  getBotResponseForDemo(...args) {
    return this.axiosIns.post(`${this.jwtConfig.getDemoEndpoint}/get-ai-bot-score`, ...args)
  }

  convertTextToBase64(...args) {
    return this.axiosIns.post('/api/tts-polly', ...args)
  }

  // payment trail
  verifyReservationPayment(args) {
    return this.axiosIns.post(`${this.jwtConfig.paymentApiEndPoint}/verify-reservation`, args)
  }

  reservationPaymentSuccess(...args) {
    return this.axiosIns.get(`${this.jwtConfig.paymentApiEndPoint}/reservation/payment_success`, ...args)
  }

  reservationSchedulePaymentSuccess(...args) {
    return this.axiosIns.post(`${this.jwtConfig.paymentApiEndPoint}/reservation/select-class-schedule/payment_success`, ...args)
  }

  reservationCreateAndReserveSchedule(reservationId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.reservationEndPoint}/${reservationId}/create-custom-schedule`, ...args)
  }

  getUserPayments(...args) {
    return this.axiosIns.get(`${this.jwtConfig.paymentApiEndPoint}/user-payments`, ...args)
  }

  reservationPaidUpgrade(reservation, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.paymentApiEndPoint}/reservation/${reservation}/update-to-paid`, ...args)
  }

  reservationUpdateToPaid(...args) {
    return this.axiosIns.post(`${this.jwtConfig.paymentApiEndPoint}/reservation/update-to-paid/payment_success`, ...args)
  }

  getClassRoomLesson(classId, courseId, lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/get-classroom-lesson?courseId=${courseId}&lessonId=${lessonId}`)
  }

  // Assets Management
  getAssets(...args) {
    return this.axiosIns.get(`${this.jwtConfig.assetsEndpoint}`, ...args)
  }

  storeAssets(...args) {
    return this.axiosIns.post(`${this.jwtConfig.assetsEndpoint}`, ...args)
  }

  updateAssets(assetId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.assetsEndpoint}/${assetId}`, ...args)
  }

  deleteAsset(assetId) {
    return this.axiosIns.delete(`${this.jwtConfig.assetsEndpoint}/${assetId}`)
  }

  getLessonInfoForVisualization(...args) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/lesson-info`, ...args)
  }

  updateLessonPlayTime(loaderId) {
    return this.axiosIns.put(`${this.jwtConfig.apiEndPoint}/update-lesson-time/${loaderId}`)
  }

  getProblemConversionSetting() {
    return this.axiosIns.get(`${this.jwtConfig.schoolSettingsEndpoint}/problem-conversion-setting`)
  }

  requestPracticeLessonsForClass(...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/request-practice`, ...args)
  }

  getUsersCount(...args) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/child/count`, ...args)
  }

  getUsersActivities(page, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/activities?page=${page}`, ...args)
  }

  addUsersToCalibration(...args) {
    return this.axiosIns.post(`${this.jwtConfig.usersEndpoint}/update-calibration`, ...args)
  }

  getUsersCanViewCalibration() {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/can-view-calibration`)
  }

  // note templates
  getNoteTemplates(...args) {
    return this.axiosIns.get(`${this.jwtConfig.noteTemplateEndPoint}`, ...args)
  }

  storeNoteTemplate(data) {
    return this.axiosIns.post(`${this.jwtConfig.noteTemplateEndPoint}`, data)
  }

  updateNoteTemplate(id, data) {
    return this.axiosIns.put(`${this.jwtConfig.noteTemplateEndPoint}/${id}`, data)
  }

  deleteNoteTemplate(id) {
    return this.axiosIns.delete(`${this.jwtConfig.noteTemplateEndPoint}/${id}`)
  }

  getTeacherNoteWithTemplate() {
    return this.axiosIns.get(`${this.jwtConfig.noteTemplateEndPoint}/teacher/note`)
  }

  speechToTextConversion(args) {
    return this.axiosIns.post('/api/speech-to-text', args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  bulkCalendarEventUpdate(...args) {
    return this.axiosIns.post(`${this.jwtConfig.calendarEventEndoint}/bulk-update`, ...args)
  }

  // stable diffusion
  setupImageGeneration(...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/setup-image-generation`, ...args)
  }

  // dall-e-3
  setupImageByDalle(...args) {
    return this.axiosIns.post(`${this.jwtConfig.aiIdeEndPoint}/setup-image-dalle`, ...args)
  }

  getDiffusionImageStatus(uuid) {
    return this.axiosIns.get(`${this.jwtConfig.aiIdeEndPoint}/get-image-status/${uuid}`)
  }

  synchImgLibrary() {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}-sync/sync-img-library`)
  }

  synchImgLibraryOtherVersion() {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}-sync/sync-other-version`)
  }

  regenerateImgLibrary(args) {
    return this.axiosIns.post(`${this.jwtConfig.imageLibraryEndpoint}/sync-imgs`, args)
  }

  getQueueProgress(name) {
    return this.axiosIns.get(`${this.jwtConfig.commonEndpoint}/queue-info/${name}`)
  }

  getCommandProcess() {
    return this.axiosIns.get(`${this.jwtConfig.commonEndpoint}/command-progress`)
  }

  getProblemDataFromLogs(logId) {
    return this.axiosIns.get(`${this.jwtConfig.gecPartnerEndPoint}/get-problem-data/${logId}`)
  }

  saveOcrProblems(...args) {
    return this.axiosIns.post(`${this.jwtConfig.apiEndPoint}/ocr-problems`, ...args)
  }

  uploadLaExcelFileUser(args) {
    return this.axiosIns.post(`${this.jwtConfig.gecPartnerEndPoint}/la/excel`, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  uploadLaScheduleExcelFileUser(args) {
    return this.axiosIns.post(`${this.jwtConfig.gecPartnerEndPoint}/la/excel-schedule`, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  uploadLaFollowUp(args) {
    return this.axiosIns.post(`${this.jwtConfig.gecPartnerEndPoint}/la/follow-up`, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  getPartnerExcelLogs(...args) {
    return this.axiosIns.get(`${this.jwtConfig.gecPartnerEndPoint}/la/excel-logs`, ...args)
  }

  getSchoolAndDistrictSettings(args) {
    if (!getUserData()) {
      return new Promise(resolve => {
        resolve({})
      })
    }
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/school-district-settings`, args)
  }

  getSystemLocale() {
    return this.axiosIns.get(`${this.jwtConfig.localeEndPoint}/get`)
  }

  updateSystemLocale(...args) {
    return this.axiosIns.post(`${this.jwtConfig.localeEndPoint}/locale-save`, ...args)
  }

  mergeSystemLocale(...args) {
    return this.axiosIns.post(`${this.jwtConfig.localeEndPoint}/locale-merge`, ...args)
  }

  automateProblem(lessonId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.automateProblemEndPoint}/automate/${lessonId}`, ...args)
  }

  automateProblemCbAudio(pageId) {
    return this.axiosIns.post(`${this.jwtConfig.curriculumEndpoint}/automate-audio/${pageId}`)
  }

  automateProblemCbImage(pageId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.curriculumEndpoint}/automate-image/${pageId}`, ...args)
  }

  bulkCbAssets(cbId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.curriculumEndpoint}/automate-bulk/${cbId}`, ...args)
  }

  // conversational mode
  setupConversationalModeProblem(classRoomId, lessonId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.conversationalMode}/setup/${classRoomId}/lesson/${lessonId}`, ...args)
  }

  checkConversationalModeProblem(testStatId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.conversationalMode}/check/${testStatId}`, ...args)
  }

  createVocabEventProblem(...args) {
    return this.axiosIns.post(`${this.jwtConfig.vocabEventEndPoint}/add`, ...args)
  }

  getVocabEventInfo(id) {
    return this.axiosIns.get(`${this.jwtConfig.vocabEventEndPoint}/${id}/info`)
  }

  uploadVocabCsv(...args) {
    return this.axiosIns.post(`${this.jwtConfig.vocabEventEndPoint}/upload-vocab-csv`, ...args)
  }

  // vocab class Room
  getVocabClassRoomDashboard(classId) {
    return this.axiosIns.get(`${this.jwtConfig.vocabClassRoomEndPoint}/${classId}/dashboard`)
  }

  // vocab word
  getVocabWordEventData(eventId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.vocabWordEndPoint}/event/${eventId}`, ...args)
  }

  submitVocabWordEventData(...args) {
    return this.axiosIns.post(`${this.jwtConfig.vocabWordEndPoint}/check-answer`, ...args)
  }

  getVocabLessonReport(eventId) {
    return this.axiosIns.get(`${this.jwtConfig.vocabWordEndPoint}/get-result/${eventId}`)
  }

  getVocabLessonReportByProgress(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.vocabWordEndPoint}/get-report-by-progress/${classId}`, ...args)
  }

  createLessonFromDataBlock(lessonId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.cbBatchEndPoint}/generate-from-data-block/${lessonId}`, ...args)
  }
}
export default JwtServiceMore

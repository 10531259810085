import { render, staticRenderFns } from "./FrameLinks.vue?vue&type=template&id=2614805a&scoped=true&"
import script from "./FrameLinks.vue?vue&type=script&lang=js&"
export * from "./FrameLinks.vue?vue&type=script&lang=js&"
import style0 from "./FrameLinks.vue?vue&type=style&index=0&id=2614805a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2614805a",
  null
  
)

export default component.exports
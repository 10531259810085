import i18n from '@/libs/i18n'
import './registerServiceWorker'

// set locale
i18n.locale = localStorage.getItem('locale')
if (!i18n.locale) {
  i18n.locale = 'en'
}

const loadLocaleMessages = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_GEC_API}/v1/locale/get?locale=${i18n.locale}`)
    if (!response.ok) {
      throw new Error('Failed to fetch locale messages')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error loading locale messages:', error)
    return {}
  }
};

// eslint-disable-next-line no-unused-expressions
(async () => {
  const messages = await loadLocaleMessages()
  Object.entries(messages).forEach(
    ([key, value]) => i18n.setLocaleMessage(key, value),
  )
  // eslint-disable-next-line global-require
  require('./app')
})()

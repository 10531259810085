var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"m-0 drag-position",class:{'fill-row': !_vm.copyPasteEnabled}},[_c('b-col',{staticClass:"problem-card-title text-center",attrs:{"md":"12"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getProblemHintsDescription(_vm.$t("student-test-module.drag-position-problem-hint")))+" ")])]),_c('b-col',{staticClass:"problem-overview-content",attrs:{"md":"12"}},[(_vm.defaultAnswers.length > 1 && _vm.shouldShowHints.hints)?_c('div',{staticClass:"text-center"},[(_vm.feedbackQuestionComputed.length > 0)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.feedbackQuestionComputed),function(ans,index){return _c('div',{key:index,staticClass:"mr-2",attrs:{"no-body":""}},[(_vm.shouldShowHints.image_hints)?_c('b-img',{staticStyle:{"height":"50px","width":"50px"},attrs:{"src":ans.image_url || ans.image,"thumbnail":"","fluid":""}}):_vm._e(),(_vm.shouldShowHints.text_hints)?_c('div',{staticClass:"small text-bold",domProps:{"innerHTML":_vm._s(ans.title)}}):_vm._e()],1)}),0):(_vm.shouldShowHints.text_hints)?_vm._l((_vm.defaultAnswers),function(ans,index){return _c('span',{key:index,staticClass:"mr-2",domProps:{"innerHTML":_vm._s(ans)}})}):_vm._e()],2):_vm._e(),(_vm.bodyAudio)?_c('div',[_c('audio-player',{attrs:{"src":_vm.bodyAudio}})],1):_vm._e(),_c('validation-observer',{ref:"submitQuestionForm"},[_c('div',{staticClass:"ck-content",class:{
             'pointer-disabled': !!_vm.feedback?.test_id
           }},[(_vm.fullHtml)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.fullHtml)}}):_c('div',{staticClass:"ck-content",class:_vm.selectedItemForTouch !== null ? 'is-being-touched' : ''},[(_vm.template && _vm.template.before_template && _vm.template.before_template.length>0 &&
              (_vm.getTemplateComponent !== 'Shapes' || _vm.getTemplateComponent === 'Shapes' && _vm.template.template[0].type !== 'fib') && _vm.getTemplateComponent !== 'side-cards' )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.beforeHtml)}}):_vm._e(),(_vm.template && _vm.template.template)?_c(_vm.getTemplateComponent,{tag:"component",attrs:{"place-holder":_vm.placeHolder,"data":_vm.template.template,"is-a-problem":true,"question-formatter":_vm.formatQuestion,"template-options":_vm.getTemplateOptions}},[((_vm.getTemplateComponent === 'Shapes' && _vm.template.template[0].type === 'fib') || _vm.getTemplateComponent == 'side-cards' )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.beforeHtml)}}):_vm._e()]):_vm._e(),(
              _vm.template &&
                _vm.template.after_template &&
                _vm.template.after_template.length > 0
            )?_c('div',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],domProps:{"innerHTML":_vm._s(_vm.afterHtml)}}):_vm._e()],1),_vm._l((_vm.localQuestions),function(question,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:'question_' + index},[(question == _vm.placeHolder)?_c('validation-provider',{attrs:{"name":'Field_' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-input',{staticClass:"mx-1 right-answer",staticStyle:{"display":"inline-block","width":"150px","margin-top":"2px"},attrs:{"id":'Field_' + (index + 1),"disabled":_vm.mode === 'feedback',"state":errors.length > 0 ? false : null,"size":"sm","name":'Field_' + (index + 1),"onpaste":"return ${copyPasteEnabled};"},on:{"input":_vm.checkValidation},model:{value:(_vm.answers[index]),callback:function ($$v) {_vm.$set(_vm.answers, index, $$v)},expression:"answers[index]"}})]}}],null,true)}):_c('span',{domProps:{"innerHTML":_vm._s(question)}})],1)})],2)]),_c('DropArea',{ref:"answerListArea",style:({
        visibility: _vm.isValidated ? 'hidden' : 'visible'
      }),attrs:{"undo-answer":_vm.undoAnswer,"start-touch":_vm.startTouch,"handle-double-click":_vm.handleDoubleClick,"start-drag":_vm.startDrag,"get-random-variant":_vm.getRandomVariant,"unescape":_vm.unescape,"is-preview-mode":_vm.isPreviewMode,"default-answers":_vm.defaultAnswers}})],1),_c('portal',{attrs:{"to":"problemFeedBack"}},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[_c('feedback',{attrs:{"feedback":_vm.feedback,"problem-statement-id":_vm.problemStatementId}})],1),(!_vm.isPreview)?_c('b-col',{staticClass:"m-0 p-0",attrs:{"md":"12"}},[_c('actions',_vm._g({attrs:{"has-feedback":_vm.hasFeedback,"is-processing":_vm.isProcessing,"level":_vm.level ? _vm.level : '',"on-submit":_vm.submit,"disabled":!_vm.isValidated,"has-minimal-view":_vm.hasMinimalView,"tooltip-text":_vm.$t('student-test-module.fill-problem-validation-message'),"display-result":_vm.displayResult,"button-title":_vm.hasFeedback ? _vm.$t('next') : _vm.$t('submit'),"engine":_vm.engine,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings}},_vm.$listeners))],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  namespaced: true,
  state: {
    activeViewOfLab: '',
    studentAnswer: '',
  },
  mutations: {
    SET_ACTIVE_LAB_VIEW(state, payload) {
      state.activeViewOfLab = payload
    },
    SET_STUDENT_ANSWER(state, payload) {
      state.studentAnswer = payload
    },
  },
}

<template>
  <div class="level-indicator m-0 p-0">
    <span
      :class="`indicator indicator-${type} ${type==='step' ? `active-level-${level}` : ''}`"
      :style="`border-bottom-color:${indicatorColor};left:${type==='step'?'':( level > 95 ? (level-7):(level-3))+'%'}`"
    />
    <template v-if="distLangLevel">
      <small
        v-for="distlang,distIndex in distLangLevel"
        :key="distIndex"
        class="lang-level"
        :class="`active-level-${distIndex} ${type==='step' ? 'text-light lang-level-sl' : 'text-dark lang-level-rp'}`"
      >
        {{ distLangLevel[distIndex].label }}
      </small>
    </template>
    <template v-else>
      <!-- <small
        v-for="lang, index in lang_levels"
        :key="index"
        class="lang-level"
        :class="`active-level-${index+1} ${type==='step' ? 'text-light lang-level-sl' : 'text-dark lang-level-rp'}`"
      >
        {{ getLangLevelTransText(lang) }}
      </small> -->
      <small
        v-for="lang, index in cefrLangLevel"
        :key="index"
        class="lang-level"
        :class="`active-level-${index} ${type==='step' ? 'text-light lang-level-sl' : 'text-dark lang-level-rp'}`"
      >
        {{ lang.label }}
      </small>
    </template>
    <!-- <ul class="lang-level">
      <li>A1</li>
      <li>A2</li>
      <li>B1</li>
      <li>B2</li>
    </ul> -->
    <div
      v-if="type!='step'"
      style="margin-top:-8px"
      class="w-100 text-center"
    >
      {{ level }}%
    </div>

    <ul
      class="swatch"
      :style="`width:${type==='step' ?'100px':'100%' }`"
    />
  </div>
</template>

<script>
import {
  checkSettings,
} from '@/utils/visibilitySettings'
import { cefrLangLevel } from '@/const/langLevel'

export default {
  props: {
    level: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'step',
    },
    indicatorColor: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      lang_levels: ['A1', 'A2', 'B1', 'B2'],
      cefrLangLevel,
    }
  },
  computed: {
    distLangLevel() {
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta

      const distLangLevelSetting = checkSettings('lang_level_indicator', schoolSetting, distSetting)?.value
      return JSON.parse(distLangLevelSetting)
    },
  },
}
</script>

<style scoped>
.swatch {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
  position: relative;
}
.level-indicator {
  position: relative;
  height: 10px;
}
.indicator {
  position: absolute;
  z-index: 10;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  border-radius: 6px;
  top: 6px;
}
.active-level-1, .active-level-A1 {
  left: 9%;
}
.active-level-2, .active-level-A2 {
  left: 32%;
}
.active-level-3, .active-level-B1 {
  left: 56%;
}
.active-level-4, .active-level-B2 {
  left: 80%;
}

.indicator-percentage{
  top: 24px !important;
}

.swatch::before {
  content: "";
  width: 100%;
  height: 12px;
  outline: 0px solid #000;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  border-radius: 10px;

  background-image: linear-gradient(
    90deg,
    #90EE90 25%,
    #77DD77 25%,
    #77DD77 50%,
    #4CBB17 50%,
    #4CBB17 75%,
    #006400 75%,
    #006400 100%
  );
}

.swatch__elem {
  margin: 0;
  padding: 0;
  outline: 0px solid #c00;
  float: left;
  padding-left: 5px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lang-level {
  position: absolute;
  margin-left: -2px;
  z-index: 10;
  mix-blend-mode: difference !important;
}
.lang-level-rp {
  top: 28px;
}
.lang-level-sl {
  top: 15px;
}
</style>

<template>
  <b-overlay
    :show="isProcessing"
    class="pt-2"
    :class="tabMenu === 0 && ' fit-width'"
  >
    <div class="bg-danger position-absolute cursor-pointer d-flex justify-content-center align-items-center rounded-circle shadow"
         style="width:18px; height:18px; right:0px; z-index:100; top:5px;"
         @click="$emit('close')"
    >
      <feather-icon
        icon="XIcon"
        size="14"
        class="text-white"
      />
    </div>
    <b-tabs
      v-model="tabMenu"
      pills
    >
      <b-tab
        active
      >
        <template #title>
          <feather-icon icon="ClipboardIcon" />{{ $t('student-lab-module.my-note') }}
        </template>
        <my-notes
          :note="myNote"
          :lesson-id="lessonId"
          :problem-id="problemId"
        />
      </b-tab>
      <b-tab v-if="self.usertype !== 'teacher'">
        <template #title>
          <feather-icon icon="UserPlusIcon" /> {{ $t('student-lab-module.teacher-notes') }}
        </template>
        <others-notes
          :notes="teacherNotes"
          :lesson-id="lessonId"
          :class-rooms="searchRooms"
          :all-students="allStudents"
          :teacher-note-for-me="teacherNoteForMe"
          :parent-selected-classrooms="selectedClassrooms"
          @getLessonNotesByPage="getLessonNotesByPage"
        />
      </b-tab>
      <b-tab v-if="self.usertype === 'teacher'">
        <template #title>
          <feather-icon icon="RadioIcon" /> {{ $t('student-lab-module.follow-up') }}
        </template>
        <follow-up :class-rooms="searchRooms"
                   :all-students="allStudents"
                   :lesson-id="lessonId"
                   :class-id="+$route.params.classId"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <template v-if="self.usertype === 'student'">
            {{ $t('student-lab-module.pairs-notes') }}
          </template>
          <template v-else>
            {{ $t('student-lab-module.student-notes') }}
          </template>
        </template>
        <others-notes
          :notes="studentNotes"
          :lesson-id="lessonId"
          :class-rooms="searchRooms"
          :all-students="allStudents"
          :teacher-note-for-me="[]"
          :parent-selected-classrooms="selectedClassrooms"
          @getLessonNotesByPage="getLessonNotesByPage"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import MyNotes from './MyNotes.vue'
import OthersNotes from './OtherNotes.vue'
import FollowUp from './FollowUp.vue'

export default {
  components: {
    BTabs,
    BTab,
    MyNotes,
    OthersNotes,
    BOverlay,
    FollowUp,
  },
  props: {
    lessonId: {
      type: Number,
      default: 0,
    },
    problemId: {
      type: Number,
      default: 0,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myNote: null,
      teacherNotes: [],
      studentNotes: [],
      self: getUserData(),
      tabMenu: 1,
      notesWatcher: null,
      lesson: null,
      isProcessing: false,
      searchRooms: [],
      selectedClassrooms: this.$route.params.classId ? [this.$route.params.classId] : [],
      allStudents: [],
      teacherNoteForMe: [],
    }
  },
  watch: {
    tabMenu(newValue) {
      if (newValue) {
        this.getLessonNotesByPage()
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.notesWatcher)
  },
  created() {
    this.getLessonNotesByPage()
    this.searchClasses('')
  },
  methods: {
    getLessonNotesByPage(classRooms = this.selectedClassrooms) {
      this.isProcessing = true
      this.selectedClassrooms = classRooms
      useJwt.getLessonNotesByPage(this.lessonId, {
        params: {
          problem_id: this.problemId > 0 ? this.problemId : null,
          class_rooms: classRooms,
          class_id: this.$route.query.classId,
        },
      }).then(response => {
        this.myNote = response.data.data?.my_note
        this.teacherNotes = response.data.data?.teacher_note
        this.studentNotes = response.data.data?.student_note
        this.lesson = response.data.data?.lesson
        this.allStudents = response.data.data.students
        this.teacherNoteForMe = response.data.data.teachers_note_for_me
        this.$emit('updateLesson', response.data.data?.lesson)
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    startWatchingNotes(classRooms = this.selectedClassrooms) {
      // TODO: make some realtime pusher requests
      this.selectedClassrooms = classRooms
      this.notesWatcher = setTimeout(() => {
        useJwt.getLessonNotesByPage(this.lessonId, {
          params: {
            class_rooms: classRooms,
          },
        }).then(response => {
          this.teacherNotes = response.data.data?.teacher_note
          this.studentNotes = response.data.data?.student_note
        }).finally(() => {
          this.startWatchingNotes()
        })
      }, 15000)
    },
    searchClasses(value) {
      const teacherId = this.self.id
      useApollo
        .searchRooms(value, teacherId)
        .then(res => {
          this.searchRooms = res.data.searchRooms
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
  },
}
</script>
<style scoped>
  .fit-width {
    width: 100%
  }
</style>

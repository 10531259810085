var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},_vm._l((_vm.data),function(card,index){return _c('div',{key:index,class:{
      'col-md-3': _vm.data.length > 3,
      'col-md-4': _vm.data.length === 3,
      'col-md-6': _vm.data.length === 2,
      'col-md-12': _vm.data.length === 1,
    }},[_c('div',{staticClass:"card"},[(card.image_url)?_c('img',{directives:[{name:"image-preview",rawName:"v-image-preview"}],staticClass:"card-img-top",class:{
          'fixed-width': _vm.data.length <= 2,
        },staticStyle:{"margin":"0 auto","padding":"4px"},attrs:{"src":card.image_url}}):_vm._e(),_c('div',{staticClass:"card-body"},[_c('h5',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticClass:"card-title mb-0 text-center",domProps:{"innerHTML":_vm._s(_vm.isAProblem ? _vm.formatQuestion(card.title,'title',index):card.title)}})])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
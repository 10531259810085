export default {
  namespaced: true,
  state: {
    settings: [],
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },

  },
  actions: {
    start({ dispatch }) {
      dispatch('studentInteractions/init', {}, { root: true })
      dispatch('appDeployment/listen', {}, { root: true })
    },

    setClassSettings({ rootState, commit, dispatch }, { settings, selfStudyClassRoom = false }) {
      const { isStudentYoung } = rootState.appConfig
      const largerFont = settings?.filter(setting => setting?.setting_name === 'larger_font')[0]?.value === 1
      const speechTries = settings?.filter(setting => setting?.setting_name === 'speech_tries')[0]?.value || 3
      const teacherChatBot = settings?.filter(setting => setting?.setting_name === 'has_chat_bot')[0]?.value || false
      // eslint-disable-next-line eqeqeq
      const fancyPlayer = settings?.filter(setting => setting?.setting_name === 'fancy_audio_player')[0]?.value == '1'
      // eslint-disable-next-line eqeqeq
      const serverSpeechCheck = settings?.filter(setting => setting?.setting_name === 'speech_server_check')[0]?.value == '1'
      commit('SET_SETTINGS', {
        speechTries, teacherChatBot, fancyPlayer, serverSpeechCheck,
      })
      if (largerFont || isStudentYoung) {
        document.body.classList.add('small-kid')
      } else {
        document.body.classList.remove('small-kid')
      }

      const avatar = settings?.find(setting => setting?.setting_name === 'avatar')?.value === 1
      commit('appConfig/UPDATE_SHOW_AVATAR', avatar, { root: true })
      if (selfStudyClassRoom) {
        commit('appConfig/UPDATE_SHOW_AVATAR', false, { root: true })
      }
      const attention = settings?.find(setting => setting?.setting_name === 'attention')?.value === 1
      if (attention && !selfStudyClassRoom) {
        dispatch('studentInteractions/dispose', {}, { root: true })
        dispatch('studentInteractions/disposeAttention', {}, { root: true })
        dispatch('studentInteractions/init', {}, { root: true })
        return
      }
      dispatch('studentInteractions/dispose', {}, { root: true })
      dispatch('studentInteractions/disposeAttention', {}, { root: true })
    },
  },
}

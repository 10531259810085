export default function handleContent(htmlString) {
  const text = htmlString.replace(/<\/?[^>]+(>|$)/g, '')
  const stringWithoutSpaces = text.replace(/\s+/g, '')
  const stringLength = stringWithoutSpaces.length
  const maxFontSize = 50
  const minFontSize = 14
  const maxWordLength = 350
  const fontPercent = (stringLength / maxWordLength) * maxFontSize
  let fontPixel = maxFontSize - fontPercent
  fontPixel = fontPixel > minFontSize ? fontPixel : minFontSize
  return {
    '--problem-feedback-font-size': `${fontPixel}px`,
  }
}

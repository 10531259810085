<template>
  <b-modal
    title="Lesson Preview"
    size="xl"
    :visible="show"
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <!-- <b-tabs v-if="!isLinearLesson"
            pills
    >
      <b-tab
        v-for="tab of tabs"
        :key="tab.value"
        :title="getLangLevelTransText(tab.label)"
        @click="selectedLangLevel = selectedLangLevels.push(tab.value)"
      >
        <iframe
          v-if="selectedLangLevels.includes(tab.value)"
          :src="`/${AUTH_USER().usertype}-demo-lesson?level=demo-2&lid=${lessonId}&problem_id=${problemId}&lang_level=${tab.value}`"
          style="height: 100vh;width: 100%;"
          frameBorder="0"
        />
      </b-tab>
    </b-tabs> -->
    <iframe
      :src="`/${AUTH_USER().usertype}-demo-lesson?level=demo-2&lid=${lessonId}&problem_id=${problemId}`"
      style="height: 100vh;width: 100%;"
      frameBorder="0"
    />
  </b-modal>
</template>
<script>
import {
  BModal,
// BTabs, BTab
}
from 'bootstrap-vue'

export default {
  components: {
    BModal,
    // BTabs,
    // BTab,
  },
  props: {
    problemId: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    lessonInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return ({
      selectedLangLevels: ['4'],
      tabs: [
        {
          value: '4',
          label: 'B2',
        },
        {
          value: '3',
          label: 'B1',
        },

        {
          value: '2',
          label: 'A2',
        },
        {
          value: '1',
          label: 'A1',
        },
      ],
    })
  },
  computed: {
    isLinearLesson() {
      return !!this.lessonInfo?.is_linear
    },
  },
}
</script>

export const LANG_LEVELS = [
  'A1', 'A2', 'B1', 'B2',
]
export const LANG_LEVELS_COLORS = [
  '#90EE90', '#77DD77', '#4CBB17', '#006400',
]
export const getLevelFromIndex = level => LANG_LEVELS[parseInt(level, 10) - 1]
export const getColorForLevel = level => LANG_LEVELS_COLORS[LANG_LEVELS.includes(level) ? LANG_LEVELS.indexOf(level) : parseInt(level, 10) - 1]
export const getLevelFromScore = score => {
  // eslint-disable-next-line no-param-reassign
  score = parseInt(score, 10)
  if (score >= 1 && score <= 25) {
    return 'A1'
  } if (score >= 26 && score <= 50) {
    return 'A2'
  } if (score >= 51 && score <= 75) {
    return 'B1'
  } if (score >= 76 && score <= 100) {
    return 'B2'
  }
  return 'none'
}

export const getColorFromScore = score => {
  // eslint-disable-next-line no-param-reassign
  score = parseInt(score, 10)
  if (score >= 1 && score <= 25) {
    return LANG_LEVELS_COLORS[0]
  } if (score >= 26 && score <= 50) {
    return LANG_LEVELS_COLORS[1]
  } if (score >= 51 && score <= 75) {
    return LANG_LEVELS_COLORS[2]
  } if (score >= 76 && score <= 100) {
    return LANG_LEVELS_COLORS[3]
  }
  return 'invalid'
}

export const getColorBasedOnProficiency = score => {
  // eslint-disable-next-line no-param-reassign
  score = parseInt(score, 10)
  if (score >= 1 && score <= 25) {
    return 'red'
  } if (score >= 26 && score <= 50) {
    return '#ff8613'
  } if (score >= 51 && score <= 75) {
    return 'blue'
  } if (score >= 76 && score <= 100) {
    return 'green'
  }
  return 'invalid'
}

export const getLanguageLevel = level => {
  const langLevel = Number(level)
  switch (langLevel) {
    case 1:
      return 'A1'
    case 2:
      return 'A2'
    case 3:
      return 'B1'
    case 4:
      return 'B2'
    default:
      return 'invalid'
  }
}

export const LANG_LEVEL_INDICATOR = {
  B2: {
    label: 'B2',
  },
  B1: {
    label: 'B1',
  },
  A2: {
    label: 'A2',
  },
  A1: {
    label: 'A1',
  },
}

export const cefrLangLevel = {
  A1: {
    label: '1',
  },
  A2: {
    label: '2',
  },
  B1: {
    label: '3',
  },
  B2: {
    label: '4',
  },
}

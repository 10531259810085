<template>
  <div class="d-flex">
    <div
      v-if="showImage"
      class="img-wrapper"
    >
      <img
        :src="vocabData.image"
        alt=""
      >
    </div>

    <div class="ml-1">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">
          {{ activeWord?.toLowerCase() ?? vocabData.vocab_word }}
          <feather-icon
            icon="Volume2Icon"
            class="cursor-pointer zoom-on-hover"
            size="18"
            @click="playAudio(vocabData.audio)"
          />
        </h4>
        <div
          v-if="index !== null"
          class="icon-btn bg-secondary d-flex justify-content-center align-items-center text-light"
        >
          <feather-icon
            icon="XIcon"
            class="cursor-pointer"
            size="18"
            @click="$emit('close-popover', index)"
          />
        </div>
      </div>
      <small
        v-if="vocabData.description_json"
        class="d-block"
      >
        {{ vocabData.description_json.text }}
        <feather-icon
          icon="Volume2Icon"
          class="cursor-pointer zoom-on-hover"
          size="18"
          @click="playAudio(vocabData.description_json.audio)"
        />
      </small>
      <small v-if="vocabData.sentence_json">
        {{ vocabData.sentence_json.text }}
        <feather-icon
          icon="Volume2Icon"
          class="cursor-pointer zoom-on-hover"
          size="18"
          @click="playAudio(vocabData.sentence_json.audio)"
        />
      </small>
      <p class="font-weight-bold mt-1 mb-0">
        {{ $t('translation-wordnet-module.synonyms') }}:
      </p>
      <p
        v-for="word in vocabData.synonyms"
        :key="word.audio"
        class="mb-0"
      >
        {{ word.text }}
        <feather-icon
          icon="Volume2Icon"
          class="cursor-pointer zoom-on-hover"
          size="16"
          @click="playAudio(word.audio)"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: () => null,
    },
    vocabData: {
      type: Object,
      required: true,
    },
    showImage: {
      type: Boolean,
      default: () => true,
    },
    activeWord: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    playAudio(audioUrl) {
      const audio = new Audio(audioUrl)
      audio.play()
    },
  },
}
</script>

<style lang="scss" scoped>
.on-hover-library:hover {
  text-decoration: underline;
}
.zoom-on-hover:hover {
  zoom: 1.5;
}
.icon-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.img-wrapper {
  width: 84px;
  height: 84px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: default;
  }
}
</style>

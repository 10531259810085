<template>
  <div class="p-1">
    <b-form-group
      v-if="problemLists.length > 1"
      label="Problems"
    >
      <v-select
        v-model="selectedProblem"
        :reduce="(text) => text.value"
        :options="problemLists"
        placeholder="Please select a problem"
        label="text"
      />
    </b-form-group>
    <lesson-notes
      v-if="lessonId"
      :key="lessonId+ '_' + selectedProblem"
      ref="lessonNotes"
      :problem-id="selectedProblem"
      :lesson-id="Number(lessonId)"
      class="w-100"
      @close="$emit('close')"
    />
    <!--
    <div
      class="media-list media-bordered"
      style="max-height: 50vh; overflow-y: scroll"
    >
      <b-media
        v-for="otherNotes of getOtherStudentsProblem"
        :key="otherNotes.id"
      >
        <div class="d-flex justify-content-between">
          <div class="media-heading">
            <b>{{ otherNotes.problemName }}</b>
          </div>
          <div>
            <feather-icon
              v-if="otherNotes.editing"
              icon="CheckIcon"
              class="mr-1 text-primary"
              role="button"
              size="20"
              @click="updateNotes(otherNotes, otherNotes.content);otherNotes.editing = false"
            />
            <feather-icon
              v-else
              icon="EditIcon"
              class="mr-1 text-primary"
              role="button"
              size="20"
              @click="otherNotes.editing = true"
            />
            <feather-icon
              icon="TrashIcon"
              class="text-danger"
              role="button"
              size="20"
              @click="noteToDelete = otherNotes.id"
            />
          </div>
        </div>
        <div
          v-if="otherNotes.editing"
          class="mt-1"
        >
          <ckeditor
            v-model="otherNotes.content"
            :config="ckEditConfig"
            @change="(text) => { updateNotes(otherNotes, text); fetchNotes() }"
          />
        </div>
        <div v-else>
          <b-card-text
            v-if="!otherNotes.fullText"
            class="mb-0 small two-line-text"
            role="button"
            @click="otherNotes.fullText = true"
          >
            <span v-html="otherNotes.content" />
          </b-card-text>
          <b-collapse
            :visible="otherNotes.fullText"
            role="button"
          >
            <div
              class="small note-content"
              @click="otherNotes.fullText = false"
            >
              <span v-html="otherNotes.content" />
            </div>
          </b-collapse>
        </div>
      </b-media>
    </div>
    <hr> -->
    <!-- <div class="pt-1">
      <b-form-group label="Notes">
        <ckeditor
          id="textarea-default"
          v-model="noteContent"
          placeholder="Type notes here.."
          rows="5"
          :config="ckEditConfig"
          no-shrink
        />
      </b-form-group>
      <div class="d-flex justify-content-between pt-1">
        <div>
          <b-button
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="
              $router.push({
                name: 'student-view-notes',
                params: { classId, lessonId },
                query: { problemId: activeProblem },
              })
            "
          >
            <feather-icon icon="ExternalLinkIcon" />
            Note Lesson
          </b-button>
        </div>
        <div>
          <b-button
            variant="outline-success"
            :disabled="!noteContent"
            size="sm"
            @click="$emit('onUpdate', noteContent); noteContent = ''"
          ><feather-icon icon="SaveIcon" />
            {{ $t("actions.save") }}
            <b-spinner
              v-show="isProcessing"
              class="mr-1"
              small
            />
          </b-button>
        </div>
      </div>
    </div> -->
    <!-- <delete-modal
      :show="!!noteToDelete"
      name="note-delete"
      title="Delete Note"
      sub-title="Are you sure you want to delete note?"
      varient="danger"
      :is-processing="isDeleting"
      :on-delete="deleteNote"
      @close="
        $emit('close');
        noteToDelete = false;
      "
    /> -->
  </div>
</template>

<script>
import {
  // BButton,
  // BMedia,
  BFormGroup,
  // BCollapse,
  // BCardText,
  // BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
// import DeleteModal from '@/views/common/components/DeleteModal.vue'
import LessonNotes from '@/views/common/lessons/Notes/index.vue'
// import CKEditor from 'ckeditor4-vue'

export default {
  components: {
    // BButton,
    // BMedia,
    vSelect,
    BFormGroup,
    LessonNotes,
    // BCollapse,
    // BCardText,
    // BSpinner,
    // DeleteModal,
    // ckeditor: CKEditor.component,
  },
  props: {
    note: {
      type: Object,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProblem: null,
      noteContent: this.note.content,
      self: getUserData(),
      otherProblemsNotes: [],
      problemLists: [],
      noteToDelete: null,
      isDeleting: false,
      ckEditConfig: {
        toolbar: [
          { name: 'basicstyles', items: ['Underline', 'Italic', 'Bold', 'colorbutton'] },
          ['TextColor', 'BGColor'],
        ],
        extraPlugins: 'colorbutton',
        colorButton_colors: 'CF5D4E,454545,FFF,DDD,CCEAEE,66AB16',
        removeButtons: 'Subscript,Superscript',
      },
    }
  },
  computed: {
    lessonId() {
      return this.$route.params.id
    },
    classId() {
      return this.$route.params.classId
    },
    getOtherStudentsProblem() {
      if (!this.selectedProblem) return this.otherProblemsNotes
      return this.otherProblemsNotes.filter(
        note => note.problemId === this.selectedProblem,
      )
    },
    activeProblem() {
      return this.$store.state.studentNotes.problemId
    },
  },
  watch: {
    note: {
      handler(newValue) {
        this.noteContent = newValue.content
      },
      deep: true,
      immediate: true,
    },
    // eslint-disable-next-line func-names
    '$store.state.studentNotes.noteKey': function () {
      this.getNotesByLesson()
    },
  },
  created() {
    this.getNotesByLesson()
  },
  methods: {
    getNotesByLesson() {
      if (!this.lessonId || window.isFetchingNotes) {
        return
      }
      window.isFetchingNotes = true
      const params = { params: { student_id: this.self.id } }
      useJwt.getNotesForLesson(this.lessonId, params).then(res => {
        const { data } = res.data
        // this.otherProblemsNotes = data.map(note => ({
        //   content: note.content,
        //   id: note.id,
        //   problemName: note.problem?.name,
        //   problemId: note.problem?.id,
        //   fullText: false,
        //   editing: false,
        // }))
        this.problemLists = [
          {
            text: 'All Problem',
            value: null,
          },
          ...data.map(note => ({
            value: note.problem?.id,
            text: note.problem?.name,
          })),
        ]
      }).finally(() => { window.isFetchingNotes = false })
    },
    // updateNotes(note, content) {
    //   const data = {
    //     problem_id: note.problemId,
    //     title: note.problemName,
    //     content: content || '-',
    //   }
    //   useJwt
    //     .updateNote(note.id, data)
    //     .then(res => {
    //       this.showSuccessMessage(res)
    //       if (this.activeProblem === note.problemId) {
    //         this.$store.dispatch(
    //           'studentNotes/getNotesForProblem',
    //           this.activeProblem,
    //         )
    //       }
    //     })
    //     .catch(error => {
    //       this.showErrorMessage(error)
    //     })
    // },
    // deleteNote() {
    //   this.isDeleting = true
    //   this.$store
    //     .dispatch('studentNotes/deleteNote', this.noteToDelete)
    //     .then(() => {
    //       this.showSuccess('Notes Successfully deleted')
    //       this.getNotesByLesson()
    //       this.noteToDelete = null
    //     })
    //     .catch(error => {
    //       this.showErrorMessage(error)
    //     })
    //     .finally(() => {
    //       this.isDeleting = false
    //     })
    // },
  },
}
</script>
<style>
.note-scroll-bar {
  max-height: 200px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125);
}
.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.note-content {
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 15px;
  word-spacing: 0px;
}
</style>

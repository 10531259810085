import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltip } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import apolloProvider from '@/libs/vue-apollo'
import vuex from 'vuex'
import LottieAnimation from 'lottie-web-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Toast, { POSITION } from 'vue-toastification'
import VueYouTubeEmbed from 'vue-youtube-embed'
import toastMixin from '@/utils/toast'
import greetingsMixin from '@/utils/greetings'
import common from '@/utils/common'
import cachedFilters from '@/utils/cachedFilters'
import Tts from '@/utils/tts'
import VueGtag from 'vue-gtag'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// eslint-disable-next-line import/order

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Import custom css file globally
import '@/assets/css/style.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(vuex)
Vue.use(VueYouTubeEmbed)
Vue.use(LottieAnimation)
Vue.use(VueSweetalert2)
Vue.use(Toast, {
  position: POSITION.TOP_CENTER,
})

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_GOOGLE_ANALYTIC,
    params: {
      send_page_view: false,
    },
  },
}, router)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import stlyes for vue-good-table
require('@core/scss/vue/libs/vue-good-table.scss')

// import styles for vue-select
require('@core/scss/vue/libs/vue-select.scss')

Vue.config.productionTip = false

if (['dev', 'release'].includes(process.env.VUE_APP_ENV)) {
  Vue.config.devtools = true
}

Vue.mixin({
  directives: {
    ...Tts,
    'b-tooltip': VBTooltip,
  },
  methods: {
    ...toastMixin, ...greetingsMixin, ...cachedFilters, ...common,
  },
})

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),

}).$mount('#app')

import i18n from '@/libs/i18n'

export const problemTags = [
  // {
  //   id: 'reading',
  //   text: 'Reading',
  // },
  // {
  //   id: 'writing',
  //   text: 'Writing',
  // },
  // {
  //   id: 'listening',
  //   text: 'Listening',
  // },
  // {
  //   id: 'speaking',
  //   text: 'Speaking',
  // },
  {
    id: 'grammar',
    text: i18n.tc('grammar'),
  },
  {
    id: 'vocabulary',
    text: i18n.tc('vocabulary'),
  },
]

export const userTags = [
  {
    value: null,
    text: i18n.tc('select-tag'),
  },
  {
    value: 'sponsor',
    text: i18n.tc('network-member-types.sponsor'),
  },
  {
    value: 'charity',
    text: i18n.tc('network-member-types.charities'),
  },
]
